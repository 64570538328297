<template>
  <v-container v-if="user" id="user-profile" fluid tag="section" data-test-page-name="User Profile">
    <v-row justify="start">
      <v-col cols="12" lg="8" md="8">
        <stemble-card class="mb-5" icon="mdi-account" color="primary">
          <template #heading>
            <h2>Profile</h2>
          </template>

          <div style="position: relative; display: flex; justify-content: center">
            <v-list-item-avatar
              min-height="13em"
              min-width="13em"
              class="align-self-center"
              color="grey"
              style="overflow: visible"
            >
              <v-avatar size="180">
                <v-img v-show="user" :src="user.avatarUrl" />
              </v-avatar>
              <v-btn
                class="pa-3"
                style="bottom: 9px; right: 5px; background: #d83d0e"
                absolute
                x-small
                elevation="2"
                icon
                color="white"
                @click="open()"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-avatar>
          </div>

          <div
            v-if="!editingUser"
            class="font-weight-medium card-title d-flex align-center justify-space-between"
            style="width: 100%"
          >
            Name: {{ user.fullName }}
            <v-btn v-if="canUpdateUser" dense color="secondary" @click="editUser">
              <v-row no-gutters justify="center" align="center">
                <v-col>
                  {{ $t('edit') }}
                </v-col>
                <v-col>
                  <v-icon class="ml-2" x-small>
                    {{ $i('common.edit') }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-btn>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="editableUser.firstName" label="First Name" />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="editableUser.lastName" label="Last Name" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <VuePhoneNumberInput
                  ref="phoneNumberProvider"
                  v-model="editableUser.phoneNumber"
                  label="Phone Number"
                  @update="phoneNumberUpdated"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6">
                <v-btn variant="primary" :disabled="!hasAcceptablePhoneNumber" @click="saveUser">
                  Save
                </v-btn>
                <v-btn variant="secondary" @click="editingUser = false">Cancel</v-btn>
              </v-col>
            </v-row>
          </div>

          <v-container class="py-5">
            <v-row v-if="user.email" no-gutters class="mt-10" justify="start" align="baseline">
              <v-col cols="auto">
                <v-icon> mdi-email </v-icon>
              </v-col>
              <v-col cols="auto">
                <v-text-field
                  v-if="editingUser && $auth.user.isSuperUser()"
                  v-model="editableUser.email"
                ></v-text-field>
                <div v-else class="mt-2 pl-sm-2 pl-xs-2 pl-lg-5 pl-md-5">
                  {{ user.email }}
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="user.phoneNumber"
              no-gutters
              class="mt-10"
              justify="start"
              align="baseline"
            >
              <v-col cols="auto">
                <v-icon> mdi-phone </v-icon>
              </v-col>
              <v-col cols="auto">
                <div class="mt-2 pl-sm-2 pl-xs-2 pl-lg-5 pl-md-5">
                  {{ user.phoneNumber }}
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-12 ml-0">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="courseLikeItems"
                    class="font-weight-bold"
                  >
                    <template v-slot:item.courseName="{item}">
                      <router-link :to="`/courses/${item.courseId}`">
                        {{ item.courseName }}
                      </router-link>
                    </template>
                    <template v-slot:item.purchase="{item}">
                      <template v-if="item.purchase">
                        <v-btn
                          v-if="
                            userIsAuthUser &&
                            !item.purchase.hasPaid &&
                            !item.purchase.isExempt &&
                            item.isTopLevelCourse
                          "
                          small
                          rounded
                          color="secondary"
                          dark
                          data-test="Purchase"
                          @click="showPaymentModal(item.purchase)"
                        >
                          {{ $t('userProfile.purchaseActivate') }}
                        </v-btn>
                        <template v-else-if="canUpdatePaymentRequests && item.isTopLevelCourse">
                          <v-btn
                            small
                            rounded
                            color="secondary"
                            dark
                            class="my-1"
                            @click="updatePaymentStatus(item.purchase, !item.purchase.hasPaid)"
                          >
                            {{
                              markAsText(
                                item.purchase.hasPaid ? 'userProfile.unpaid' : 'userProfile.paid'
                              )
                            }}
                          </v-btn>
                          <br />
                          <v-btn
                            small
                            rounded
                            color="secondary"
                            dark
                            class="my-1"
                            @click="updateExemptStatus(item.purchase, !item.purchase.isExempt)"
                          >
                            {{
                              markAsText(
                                item.purchase.isExempt
                                  ? 'userProfile.required'
                                  : 'userProfile.exempt'
                              )
                            }}
                          </v-btn>
                        </template>
                        <v-chip
                          v-else-if="
                            !item.isTopLevelCourse ||
                            item.purchase.hasPaid ||
                            item.purchase.isExempt
                          "
                          filter
                          filter-icon="mdi-check"
                          :data-test="item.purchase.hasPaid ? 'Paid' : 'No Payment Required'"
                        >
                          <v-icon left>
                            {{ item.purchase.hasPaid ? 'mdi-check-circle' : 'mdi-information' }}
                          </v-icon>
                          {{
                            $t(
                              item.purchase.hasPaid
                                ? 'userProfile.paid'
                                : 'userProfile.noPaymentRequired'
                            )
                          }}
                        </v-chip>
                      </template>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </stemble-card>
      </v-col>
    </v-row>
    <v-dialog v-model="paymentDialog.visible" width="400">
      <v-card data-test="payment-dialog">
        <template v-if="!paymentDialog.hasAccessCode">
          <v-card-text>
            {{ $t('payments.howWouldYouLikeToPay') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              data-test="i-have-access-code-button"
              @click="paymentDialog.hasAccessCode = true"
              >{{ $t('payments.iHaveAccessCode') }}</v-btn
            >
            <v-btn color="secondary" :loading="isRedirecting" @click="redirectToCheckout">{{
              $t('payments.payOnline')
            }}</v-btn>
          </v-card-actions>
        </template>
        <template v-else>
          <v-card-text>
            <v-text-field
              v-model="paymentDialog.accessCode"
              v-mask="'WWWWW-WWWWW-WWWWW-WWWWW'"
              v-format-input="(item) => item.toUpperCase()"
              :label="$t('payments.accessCode')"
              :disabled="isPayingWithAccessCode"
              data-test="access-code-input"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="paymentDialog.hasAccessCode = false">{{ $t('cancel') }}</v-btn>
            <v-spacer />
            <v-btn
              color="secondary"
              :loading="isPayingWithAccessCode"
              data-test="pay-with-access-code-button"
              @click="payWithAccessCode()"
              >{{ $t('payments.payWithAccessCode') }}</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
  <not-found v-else-if="!isUserLoading" />
</template>

<script>
import {Course, CourseSection} from '@/courses/models';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import PaymentRequest from '@/payments/models/PaymentRequest';

import StembleCard from '@/common/components/StembleCard.vue';
import {ErrorCode} from '@/common/api/ErrorCode';
import moment from 'moment';
import {FRIENDLY_DATE_YEAR_FORMAT} from '@/datetime/utils/datetime';
import User from '@/users/models/User';
import NotFound from '../../errors/views/NotFound';
import CourseRole from '../models/CourseRole';
import {useFileDialog} from '@vueuse/core';
import {ref, computed} from '@vue/composition-api';
import {useAuthService} from '../../auth/services/authService';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: 'UserProfile',
  components: {NotFound, StembleCard, VuePhoneNumberInput},
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const auth = useAuthService();

    const user = computed(() => {
      if (props.userId) {
        return User.query().with('courseRoles').find(props.userId);
      }

      return auth.user;
    });

    const {open, onChange} = useFileDialog({
      multiple: false,
      accept: '.png',
    });

    const saveUserAvatar = (file) => {
      User.api.patch(user.value.id, {avatar: file});
    };

    onChange(([file]) => {
      saveUserAvatar(file);
    });

    return {open, user};
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('course'),
          align: 'start',
          sortable: false,
          value: `courseName`,
        },
        {
          text: this.$t('courseSettings.startDate'),
          align: 'start',
          sortable: false,
          value: 'startDate',
        },
        {
          text: this.$t('section'),
          align: 'start',
          sortable: false,
          value: 'sectionName',
        },
        {
          text: '',
          value: 'purchase',
          sortable: false,
        },
      ],
      paymentDialog: {
        visible: false,
        paymentRequest: null,
        hasAccessCode: false,
        accessCode: null,
      },
      editingUser: false,
      editableUser: null,
      isPhoneNumberValid: false,
    };
  },
  computed: {
    isCoursesLoaded() {
      return this.$loadingFlags.isLoaded(LoadingFlag.CurrentUserCourses);
    },
    isUserLoading() {
      return this.$loadingFlags.isLoading(LoadingFlag.UserProfileUser);
    },
    userIsAuthUser() {
      return this.user.id === this.$auth.user.id;
    },
    hasAcceptablePhoneNumber() {
      return this.isPhoneNumberValid || !this.editableUser.phoneNumber;
    },
    courseRoles() {
      return this.user.courseRoles;
    },
    courseLikes() {
      return this.courseRoles
        .map((cr) => {
          return (
            Course.find(cr.courseLikeId) ||
            CourseSection.fullQuery({course: true}).find(cr.courseLikeId)
          );
        })
        .filter((cl) => cl);
    },
    courseLikesByCourse() {
      const courseLikesByCourse = {};
      for (const courseLike of this.courseLikes) {
        const courseId = courseLike.courseId || courseLike.id;
        courseLikesByCourse[courseId] = courseLikesByCourse[courseId] || [];
        courseLikesByCourse[courseId].push(courseLike);
      }

      for (const [k, v] of Object.entries(courseLikesByCourse)) {
        courseLikesByCourse[k] = v.sort((item1, item2) => {
          return item1.courseId === undefined ? -1 : item1.id - item2.id;
        });
      }
      return courseLikesByCourse;
    },
    courseLikeItems() {
      const allCourseSections = [];
      for (const courseLikes of Object.values(this.courseLikesByCourse)) {
        let isFirst = true;
        for (const courseLike of courseLikes) {
          const courseId = courseLike.courseId;
          const courseName = courseLike.isCourse ? courseLike.name : courseLike.course.name;
          const startDate = courseLike.isCourse
            ? courseLike.startDate
            : courseLike.course.startDate;

          // TODO: this logic will have to change if PaymentRequests don't apply to child course
          const paymentRequest = this.paymentRequestsByCourseId[courseLike.topLevelCourseId];

          const courseWithSectionObject = {
            courseId: courseId,
            courseName: isFirst ? courseName : '',
            startDate: isFirst ? moment(startDate).format(FRIENDLY_DATE_YEAR_FORMAT) : '',
            sectionName: courseLike.isCourse ? '' : courseLike.name,
            purchase: isFirst ? paymentRequest : null,
            isTopLevelCourse: courseLike.topLevelCourseId === courseLike.courseId,
          };
          isFirst = false;

          allCourseSections.push(courseWithSectionObject);
        }
      }
      return allCourseSections;
    },
    paymentRequests() {
      return PaymentRequest.fullQuery().where('userId', this.user.id).all();
    },
    paymentRequestsByCourseId() {
      return this.paymentRequests.reduce((acc, item) => {
        acc[item.courseId] = item;
        return acc;
      }, {});
    },
    isRedirecting() {
      return this.$loadingFlags.isLoading(LoadingFlag.RedirectingToCheckout);
    },
    isPayingWithAccessCode() {
      return this.$loadingFlags.isLoading(LoadingFlag.PayingWithAccessCode);
    },
    canUpdatePaymentRequests() {
      return this.$gate.can('update', 'PaymentRequest');
    },
    canUpdateUser() {
      if (this.user) {
        return this.$gate.can('update', 'User', this.user.id);
      }
      return false;
    },
  },
  watch: {
    userId() {
      this.fetchData();
    },
    'paymentDialog.visible'(newValue) {
      if (newValue === false) {
        this.resetPaymentDialog();
      }
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    phoneNumberUpdated({isValid}) {
      this.isPhoneNumberValid = isValid;
    },
    fetchData() {
      const userId = this.userId || this.$auth.user.id;

      this.$loadingFlags
        .loadingHandler(LoadingFlag.UserProfileUser, () =>
          CourseRole.api.fetch({
            user: userId,
            includeUser: true,
            includeRole: true,
          })
        )
        .catch(this.$errorReporting.errorDialogHandler);

      this.$loadingFlags
        .loadingHandler(LoadingFlag.UserProfileCourses, () =>
          Course.api.fetch({user: userId, checkSections: true})
        )
        .catch(this.$errorReporting.errorDialogHandler);

      this.$loadingFlags
        .loadingHandler(LoadingFlag.CurrentUserPaymentRequests, () =>
          PaymentRequest.api.fetch({user: userId})
        )
        .catch(this.$errorReporting.errorDialogHandler);
    },
    payWithAccessCode() {
      this.$loadingFlags
        .loadingHandler(LoadingFlag.PayingWithAccessCode, () =>
          PaymentRequest.api.payWithAccessCode(
            this.paymentDialog.paymentRequest.id,
            this.paymentDialog.accessCode
          )
        )
        .then(() => this.hidePaymentModal())
        .catch((err) => this.payWithAccessCodeCatch(err));
    },
    async payWithAccessCodeCatch(err) {
      if (err && err.response && err.response.data.code) {
        const code = err.response.data.code;
        if (code === ErrorCode.ACCESS_CODE_ALREADY_LINKED) {
          return this.showErrorText(err, this.$t('payments.alreadyLinked'));
        } else if (code === ErrorCode.ACCESS_CODE_NOT_FOR_COURSE) {
          return this.showErrorText(err, this.$t('payments.notForCourse'));
        } else if (code === ErrorCode.ACCESS_CODE_INVALID) {
          return this.showErrorText(err, this.$t('payments.invalidCode'));
        }
      }
      return this.$errorReporting.errorDialogHandler(err);
    },
    updatePaymentRequest(paymentRequest, confirmTranslationKey, data) {
      const course = Course.find(paymentRequest.courseId);
      const text = this.buildConfirmText(this.$t(confirmTranslationKey), course);

      if (!confirm(text)) {
        return;
      }

      return PaymentRequest.api
        .patch(paymentRequest.id, data)
        .catch(this.$errorReporting.errorDialogHandler);
    },
    updatePaymentStatus(paymentRequest, hasPaid) {
      return this.updatePaymentRequest(
        paymentRequest,
        hasPaid ? 'userProfile.paid' : 'userProfile.unpaid',
        {
          hasPaid,
        }
      );
    },
    updateExemptStatus(paymentRequest, isExempt) {
      return this.updatePaymentRequest(
        paymentRequest,
        isExempt ? 'userProfile.exempt' : 'userProfile.required',
        {
          isExempt,
        }
      );
    },
    showErrorText(err, text) {
      return this.$errorReporting
        .showErrorDialog(err, {
          text,
        })
        .catch(() => {});
    },
    showPaymentModal(paymentRequest) {
      this.paymentDialog.paymentRequest = paymentRequest;
      this.paymentDialog.visible = true;
    },
    hidePaymentModal() {
      this.paymentDialog.visible = false;
    },
    redirectToCheckout() {
      this.$loadingFlags
        .loadingHandler(LoadingFlag.RedirectingToCheckout, () =>
          this.$stripe.redirectToPaymentRequestCheckout(this.paymentDialog.paymentRequest.id)
        )
        .catch(this.$errorReporting.errorDialogHandler);
    },
    resetPaymentDialog() {
      this.paymentDialog.accessCode = null;
      this.paymentDialog.hasAccessCode = false;
      this.paymentDialog.paymentRequest = null;
    },
    // TODO: rename to editUser() and editingUser boolean
    editUser() {
      this.editableUser = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        phoneNumber: this.user.phoneNumber,
        email: this.user.email,
      };
      this.editingUser = true;
    },
    async saveUser() {
      await User.api
        .patch(this.user.id, this.editableUser)
        .catch(this.$errorReporting.errorDialogHandler)
        .finally(() => {
          this.editingUser = false;
        });

      if (this.$auth.user.isSuperUser() && this.editableUser.email !== this.user.email) {
        await this.updateEmail();
      }
    },
    updateEmail() {
      this.updatingEmail = true;
      return User.api
        .updateEmail(this.user.id, this.editableUser.email)
        .catch(this.$errorReporting.errorDialogHandler)
        .finally(() => (this.updatingEmail = false));
    },
    buildConfirmText(translationKey, course) {
      return this.$t('userProfile.confirmText', [this.$t(translationKey), course.name]);
    },
    markAsText(translationKey) {
      return this.$t('userProfile.setAs', [this.$t(translationKey)]);
    },
  },
};
</script>
